import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { IconButton } from 'components/buttons';
import planStatusAtom from 'stores/planStatusAtom';
import { useAtom } from 'jotai';
import { nestTernary } from 'utils/nestTernary';
import { CommonMenu, LogoutMenuBtn } from './CommonMenu';

type MenuProps = {
  showMenu: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  isDesktop?: boolean;
};

export const Menu = ({ showMenu, setShowMenu, isDesktop }: MenuProps) => {
  // ==================== INIT =====================
  const dropDownRef = useRef<HTMLDivElement>(null);
  const userRef = useRef<HTMLButtonElement>(null);
  const [planStatus] = useAtom(planStatusAtom);
  const userLetterRegExp = /[0-9A-Z]/;
  const userLetter = planStatus.email?.[0]?.toUpperCase();

  const closeBtnStyle = planStatus?.status !== 'free' ? 'bg-primaryLow' : 'bg-menuGray';

  const mbMenuPosition = showMenu ? 'translate-x-0 pointer-events-auto' : 'translate-x-full';
  // ==================== HOOKS =====================
  // menu in modal form closes when places other than modal menu is clicked.
  useEffect(() => {
    function closeMenu(e: MouseEvent): void {
      if (
        dropDownRef.current
        && userRef.current
        && !dropDownRef.current.contains(e.target as Node)
        && !userRef.current.contains(e.target as Node)
      ) {
        setShowMenu(false);
      }
    }
    document.addEventListener('mousedown', closeMenu);
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, [dropDownRef]);

  return (

    isDesktop ? (
      <div className="relative">
        {/* ====== 0. Shown on ML ===== */}
        <button
          type="button"
          aria-label="open or close menu button"
          className={`w-24 h-24 flex justify-center items-center
              md:w-[68px] md:h-48 lg:w-[72px]`}
          ref={userRef}
          onClick={() => setShowMenu(cur => !cur)}
          >
          {userLetter ? (
            nestTernary(
              userLetterRegExp.test(userLetter),
              <img
                src={`/icons/profile/${userLetter}.svg`}
                alt="profile icon"
                className="w-48 h-48"
                draggable={false}
                />,
              <div
                className={`w-[21.3px] h-[21.3px] flex justify-center
                    items-center text-header2XS rounded-full bg-gray text-surface
                    md:w-[29.3px] md:h-[29.3px] md:mr-[7.3px] md:text-headerS
                    lg:w-32 lg:h-32 lg:mr-8`}
                >
                {userLetter}
              </div>,
            )
          ) : (
            <img
              src="/icons/solid/i_profile_solid.svg"
              className="w-48 h-48"
              alt="round profile icon"
              />
          )}
          <img
            alt={showMenu ? 'arrow pointing upward' : 'arrow pointing downward'}
            className="w-24 h-24"
            src={showMenu ? '/icons/outline/i_up.svg' : '/icons/outline/i_down.svg'}
            />
        </button>
        {/* drop down menu */}
        {showMenu && (
        <div
          ref={dropDownRef}
          className={`w-[320px] absolute top-[58px] right-0 flex flex-col
                items-center justify-center bg-surface overflow-auto
                cursor-auto pointer-events-auto rounded-md shadow-card
                lg:rounded-lg`}
            >
          <CommonMenu />
          <LogoutMenuBtn setShowMenu={setShowMenu} />
        </div>
        )}
      </div>
    ) : (
      <>
        <button
          type="button"
          className={`w-24 h-24 flex justify-center items-center
              md:w-[68px] md:h-48 lg:w-[72px]`}
          ref={userRef}
          aria-label="open or close menu button"
          onClick={() => setShowMenu(cur => !cur)}
          >
          <img
            src="/icons/outline/i_menu.svg"
            alt="hamburger icon"
            className="w-24 h-24"
            />
        </button>
        <div
          className={`w-full h-[min(100dvh,_100vh)] fixed top-0 right-0
              bottom-0 left-0 flex flex-col justify-between overflow-y-auto 
              bg-surface transition-header ${mbMenuPosition}`}
          >
          <div>
            <div
              className={`h-48 px-24 flex  items-center justify-end
                ${closeBtnStyle}`}
              >
              <IconButton
                alt="close modal button"
                size={24}
                src="/icons/outline/i_close.svg"
                onClick={() => setShowMenu(false)}
                />
            </div>
            <CommonMenu />
          </div>
          <div className="flex items-center">
            <LogoutMenuBtn setShowMenu={setShowMenu} />
          </div>
        </div>
      </>
    )

  );
};

export default Menu;
